"use client";

import Link from "next/link";
import { Icon } from "@iconify/react";
import { useI18n } from "@/i18n/client";
import { getLocalizedHref } from "@/utils/localization";

export const Footer = () => {
  const { t, locale } = useI18n();

  const navLinks = [
    { name: t("nav.home"), href: "/" },
    { name: t("nav.place"), href: "/#le-lieu" },
    { name: t("nav.services"), href: "/#services" },
    { name: t("nav.rooms"), href: "/#chambres" },
    { name: t("nav.reviews"), href: "/#avis" },
    { name: t("nav.contact"), href: "/#contact" },
  ];

  return (
    <footer className="relative bg-accent px-6 py-16 text-black ph:px-24 md:px-48 lg:px-72 xl:px-96">
      <div className="relative flex flex-col gap-12 lg:flex-row lg:justify-between">
        {/* Brand Section */}
        <div className="flex w-full flex-col items-start lg:w-1/3">
          <Link
            href={getLocalizedHref("/", locale)}
            className="flex items-center gap-2"
          >
            <img
              src="/images/logo.svg"
              alt="Les Chambres d'Anne"
              className="h-10"
            />
          </Link>
          <p className="mt-4 text-xl">{t("footer.tagline")}</p>
          <div className="mt-6 flex items-center gap-2">
            <Icon icon="mdi:phone" className="h-5 w-5" />
            <span className="text-xl">07 86 11 50 32</span>
          </div>
          <div className="mt-2 flex items-center gap-2">
            <Icon icon="mdi:email" className="h-5 w-5" />
            <Link
              href="mailto:chambresdanne@orange.fr"
              className="text-xl hover:underline"
            >
              chambresdanne@orange.fr
            </Link>
          </div>
        </div>

        <div className="flex w-full flex-col gap-12 lg:w-2/3 lg:flex-row lg:justify-end">
          {/* Navigation Section */}
          <div className="space-y-4">
            <h3 className="text-3xl font-semibold">{t("footer.navigation")}</h3>
            <nav className="grid grid-cols-2 gap-x-8 gap-y-3 text-lg sm:grid-cols-3">
              {navLinks.map((link) => (
                <Link
                  key={link.href}
                  href={getLocalizedHref(link.href, locale)}
                  className="text-xl hover:underline"
                >
                  {link.name}
                </Link>
              ))}
            </nav>
          </div>

          {/* Address Section */}
          <div className="space-y-4 lg:ml-12">
            <h3 className="text-3xl font-semibold">{t("footer.address")}</h3>
            <div className="flex flex-col gap-2 text-xl">
              <p>Les Chambres d&apos;Anne</p>
              <p>439 Route de Buchy</p>
              <p>76750 Vieux-Manoir</p>
              <Link
                href="https://maps.app.goo.gl/6YzQtJtpaKGJ9WGy6"
                target="_blank"
                className="mt-2 flex items-center gap-2 hover:underline"
              >
                <Icon icon="mdi:google-maps" className="h-6 w-6" />
                <span>{t("footer.viewOnGoogleMaps")}</span>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Bottom Section */}
      <div className="mt-16 flex flex-col-reverse items-start justify-between gap-4 border-t border-black/10 pt-6 text-sm text-black/70 ph:flex-row ph:items-center">
        <div className="flex flex-col gap-1 text-left sm:flex-row sm:gap-4">
          <p className="text-lg">
            © {new Date().getFullYear()} Les Chambres d&apos;Anne
          </p>
          <p className="text-lg">
            {t("footer.developedBy")}{" "}
            <Link
              href="https://www.virtuosa.fr"
              className="text-lg hover:underline"
              target="_blank"
            >
              Virtuosa
            </Link>
          </p>
        </div>
        <div className="flex gap-4">
          <Link
            href={getLocalizedHref("/cookies", locale)}
            className="text-lg hover:underline"
          >
            {t("footer.cookies")}
          </Link>
          <Link
            href={getLocalizedHref("/mentions-legales", locale)}
            className="text-lg hover:underline"
          >
            {t("footer.legalNotice")}
          </Link>
        </div>
      </div>
    </footer>
  );
};
