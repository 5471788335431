"use client";

import { useState, useRef, useEffect } from "react";
import { MotionDiv, AnimatePresence } from "@/components/ui/MotionDiv";
import Link from "next/link";
import { useRouter, usePathname } from "next/navigation";

import { useI18n } from "@/i18n/client";
import { locales, defaultLocale } from "@/i18n/settings";
import { Icon } from "@iconify/react";
import { getLocalizedHref } from "@/utils/localization";

interface HeaderProps {
  className?: string;
}

export const Header = ({ className = "" }: HeaderProps) => {
  const router = useRouter();
  const pathname = usePathname();
  const isHomePage = pathname === "/";
  const [isPassedHero, setIsPassedHero] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const headerRef = useRef<HTMLDivElement>(null);
  const lastScrollY = useRef(0);
  const [mounted, setMounted] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { t, locale, changeLocale } = useI18n();

  useEffect(() => {
    setMounted(true);
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const heroHeight =
        document.getElementById("hero-section")?.offsetHeight ||
        window.innerHeight;

      setIsVisible(currentScrollY < lastScrollY.current || currentScrollY < 50);
      setIsPassedHero(currentScrollY > heroHeight - 100);
      setIsScrolled(currentScrollY > heroHeight - 100);
      lastScrollY.current = currentScrollY;
    };

    handleScroll(); // Initial check
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.body.style.overflow = "";
    };
  }, []);

  // Nouvelle fonction pour changer la langue avec rechargement complet
  const handleLanguageChange = (lang: string) => {
    // Stocker une indication que la page doit défiler vers le haut après le rechargement
    sessionStorage.setItem("scrollToTop", "true");

    if (lang === defaultLocale) {
      window.location.href = "/";
    } else {
      window.location.href = `/?lang=${lang}`;
    }
  };

  useEffect(() => {
    // Vérifier si la page doit défiler vers le haut
    const shouldScrollToTop = sessionStorage.getItem("scrollToTop") === "true";
    if (shouldScrollToTop) {
      // Défiler vers le haut
      window.scrollTo(0, 0);
      // Nettoyer le stockage
      sessionStorage.removeItem("scrollToTop");
    }
  }, [mounted]);

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isMobileMenuOpen]);

  if (!mounted) {
    return null;
  }

  const menuVariants = {
    closed: {
      opacity: 0,
      transition: { duration: 0.5 },
    },
    open: {
      opacity: 1,
      transition: { duration: 0.5 },
    },
  };

  const contentVariants = {
    closed: { y: 50, opacity: 0 },
    open: {
      y: 0,
      opacity: 1,
      transition: {
        delay: 0.3,
        duration: 0.8,
        ease: "easeInOut",
      },
    },
  };

  const linkVariants = {
    closed: { y: 20, opacity: 0 },
    open: (i: number) => ({
      y: 0,
      opacity: 1,
      transition: { delay: 0.5 + i * 0.1, duration: 0.5 },
    }),
  };

  const staggerAnimationVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.2 + i * 0.1,
        duration: 0.6,
        ease: "easeOut",
      },
    }),
  };

  const buttonAnimationVariant = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delay: 0.8,
        duration: 0.5,
        ease: "easeOut",
      },
    },
  };

  const languageSelectorVariant = {
    hidden: { opacity: 0, x: -10 },
    visible: (i: number) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: 0.5 + i * 0.1,
        duration: 0.4,
        ease: "easeOut",
      },
    }),
  };

  const phoneAnimationVariant = {
    hidden: { opacity: 0, x: 10 },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        delay: 0.7,
        duration: 0.5,
        ease: "easeOut",
      },
    },
  };

  const navLinks = [
    { name: t("nav.home"), href: "/" },
    { name: t("nav.place"), href: "/#le-lieu" },
    { name: t("nav.services"), href: "/#services" },
    { name: t("nav.rooms"), href: "/#chambres" },
    { name: t("nav.reviews"), href: "/#avis" },
    { name: t("nav.contact"), href: "/#contact" },
  ];

  return (
    <>
      <header
        ref={headerRef}
        className={`fixed left-0 right-0 z-40 px-6 py-4 transition-all duration-300 ph:px-24 md:px-48 lg:px-72 xl:px-96 ${className} ${
          isVisible ? "translate-y-0" : "-translate-y-[200%]"
        } ${
          (isPassedHero && !isMobileMenuOpen) || !isHomePage
            ? "top-0 h-32 bg-primary text-black"
            : "top-6 !h-fit bg-transparent py-[8] text-white"
        }`}
      >
        <div className="flex h-full w-full items-center justify-between">
          {/* Desktop Navigation - à gauche */}
          <div className="hidden max-w-[60%] flex-shrink items-center gap-4 xl:flex">
            {navLinks.map((item, index) => (
              <MotionDiv
                key={item.name}
                initial="hidden"
                animate="visible"
                custom={index}
                variants={staggerAnimationVariants}
              >
                <Link
                  href={getLocalizedHref(item.href, locale)}
                  className={`text-lg uppercase transition-all duration-300 ${
                    isScrolled || !isHomePage ? "text-black" : "text-white"
                  } hover:cursor-pointer hover:text-black`}
                >
                  {item.name}
                </Link>
              </MotionDiv>
            ))}
          </div>

          {/* Partie gauche en mobile: langue + réservation dans un seul bloc */}
          <div className="flex items-center gap-4 xl:hidden">
            {/* Sélecteur de langue mobile */}
            <div className="flex items-center gap-2">
              {locales.map((lang, index) => (
                <MotionDiv
                  key={lang}
                  initial="hidden"
                  animate="visible"
                  custom={index}
                  variants={languageSelectorVariant}
                >
                  <button
                    onClick={() => handleLanguageChange(lang)}
                    className={`text-lg font-medium transition-all ${
                      isMobileMenuOpen
                        ? locale === lang
                          ? "text-black underline"
                          : "text-black/70 hover:text-black"
                        : isScrolled || !isHomePage
                          ? locale === lang
                            ? "text-black underline"
                            : "text-black/70 hover:text-black"
                          : locale === lang
                            ? "text-white underline"
                            : "text-white/70 hover:text-white"
                    }`}
                  >
                    {lang.toUpperCase()}
                  </button>
                </MotionDiv>
              ))}
            </div>

            {/* Bouton de réservation mobile - caché quand le menu est ouvert */}
            {!isMobileMenuOpen && (
              <MotionDiv
                initial="hidden"
                animate="visible"
                variants={buttonAnimationVariant}
              >
                <Link
                  href="https://www.gites-de-france.com/fr/normandie/seine-maritime/les-chambres-dhotes-danne-76g24024"
                  target="_blank"
                  className="rounded-full bg-tertiary px-6 py-2 text-xl font-medium uppercase text-white shadow-md transition-all hover:bg-[#3d6649] hover:shadow-lg"
                >
                  {t("cta.book")}
                </Link>
              </MotionDiv>
            )}
          </div>

          {/* Partie droite desktop */}
          <div className="hidden min-w-max items-center justify-end space-x-4 xl:flex">
            {/* Sélecteur de langue desktop */}
            <div className="flex items-center gap-4">
              {locales.map((lang, index) => (
                <MotionDiv
                  key={lang}
                  initial="hidden"
                  animate="visible"
                  custom={index}
                  variants={languageSelectorVariant}
                >
                  <button
                    onClick={() => handleLanguageChange(lang)}
                    className={`text-lg font-medium transition-all duration-300 ${
                      locale === lang
                        ? isScrolled || !isHomePage
                          ? "text-black underline"
                          : "text-white underline"
                        : isScrolled || !isHomePage
                          ? "text-black/70 hover:text-black"
                          : "text-white/70 hover:text-white"
                    }`}
                  >
                    {lang.toUpperCase()}
                  </button>
                </MotionDiv>
              ))}
            </div>

            {/* Numéro de téléphone sans bouton - Mise à jour avec Iconify */}
            <MotionDiv
              initial="hidden"
              animate="visible"
              variants={phoneAnimationVariant}
              className="flex items-center gap-2 transition-all duration-300"
            >
              <Icon
                icon="mdi:phone"
                className="h-5 w-5 transition-all duration-300"
                color={isScrolled || !isHomePage ? "black" : "white"}
              />
              <span
                className={`transition-all duration-300 ${
                  isScrolled || !isHomePage ? "text-black" : "text-white"
                } text-xl font-medium`}
              >
                07 86 11 50 32
              </span>
            </MotionDiv>

            {/* Bouton de réservation desktop */}
            <MotionDiv
              initial="hidden"
              animate="visible"
              variants={buttonAnimationVariant}
            >
              <Link
                href="https://www.gites-de-france.com/fr/normandie/seine-maritime/les-chambres-dhotes-danne-76g24024"
                target="_blank"
                className={`transition-all duration-300 ${
                  isScrolled
                    ? "bg-secondary text-white"
                    : "bg-tertiary text-white"
                } rounded-full px-6 py-2 text-lg font-medium uppercase shadow-md hover:bg-[#3d6649] hover:shadow-lg`}
              >
                {t("cta.book")}
              </Link>
            </MotionDiv>
          </div>

          {/* Mobile Menu Button - à droite */}
          <MotionDiv
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.6, duration: 0.4 }}
            className="xl:!hidden"
          >
            <button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="relative z-50 h-6 w-6 xl:hidden"
              aria-label="Toggle menu"
            >
              {isMobileMenuOpen ? (
                // X icon when menu is open
                <>
                  <div
                    style={{ height: "1.5px" }}
                    className="absolute top-1/2 w-6 -translate-y-1/2 rotate-45 bg-black"
                  ></div>
                  <div
                    style={{ height: "1.5px" }}
                    className="absolute top-1/2 w-6 -translate-y-1/2 -rotate-45 bg-black"
                  ></div>
                </>
              ) : (
                // Hamburger icon when menu is closed
                <>
                  <div
                    style={{ height: "1.5px" }}
                    className={`absolute top-1 w-6 ${
                      isScrolled || !isHomePage ? "bg-black" : "bg-white"
                    }`}
                  ></div>
                  <div
                    style={{ height: "1.5px" }}
                    className={`absolute top-1/2 w-6 -translate-y-1/2 ${
                      isScrolled || !isHomePage ? "bg-black" : "bg-white"
                    }`}
                  ></div>
                  <div
                    style={{ height: "1.5px" }}
                    className={`absolute bottom-1 w-6 ${
                      isScrolled || !isHomePage ? "bg-black" : "bg-white"
                    }`}
                  ></div>
                </>
              )}
            </button>
          </MotionDiv>
        </div>
      </header>

      {/* Mobile Menu Overlay */}
      <AnimatePresence>
        {isMobileMenuOpen && (
          <MotionDiv
            className={`fixed inset-0 z-30 bg-primary xl:hidden ${
              isScrolled ? "bg-primary" : ""
            }`}
            initial="closed"
            animate="open"
            exit="closed"
            variants={menuVariants}
          >
            <MotionDiv
              key={locale}
              className="flex h-full flex-col items-center justify-center px-4"
              variants={contentVariants}
            >
              <div className="flex w-full flex-col items-center space-y-8 text-center">
                {navLinks.map((item, index) => (
                  <MotionDiv
                    key={item.name}
                    custom={index}
                    variants={linkVariants}
                  >
                    <Link
                      href={getLocalizedHref(item.href, locale)}
                      className="block text-2xl font-light uppercase text-black transition-colors duration-300"
                      onClick={() => {
                        setIsMobileMenuOpen(false);
                      }}
                    >
                      {item.name}
                    </Link>
                  </MotionDiv>
                ))}

                {/* Numéro de téléphone - Mise à jour avec Iconify */}
                <MotionDiv custom={navLinks.length} variants={linkVariants}>
                  <div className="mt-6 flex items-center gap-2">
                    <Icon icon="mdi:phone" className="h-5 w-5" color="black" />
                    <span className="text-2xl text-black">07 86 11 50 32</span>
                  </div>
                </MotionDiv>

                {/* Bouton de réservation dans le menu mobile */}
                <MotionDiv custom={navLinks.length + 1} variants={linkVariants}>
                  <Link
                    href="https://www.gites-de-france.com/fr/normandie/seine-maritime/les-chambres-dhotes-danne-76g24024"
                    target="_blank"
                    className="mt-4 rounded-full bg-[#4A7C59] px-6 py-3 text-xl font-medium uppercase text-white shadow-md"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    {t("cta.bookNow")}
                  </Link>
                </MotionDiv>
              </div>
            </MotionDiv>
          </MotionDiv>
        )}
      </AnimatePresence>
    </>
  );
};

export default Header;
